/* ==========================================================
 top
========================================================== */
@import "mixin";

/* hero-top-01
---------------------------------------------------------- */
.hero-top-01 {
  position: relative;
  overflow: hidden;

  .hero-top-slider {
    position: relative;
    z-index: 1;
  }

  .hero-top-video{
    position: static !important;
    //overflow: visible !important;//vidbacking.js側でoverflow:hiddenを追加する記述がある
    video{
      width: 100%;
      @media screen and (max-width: 768px) {
        display: none;
      }
      &.vidbacking-active-block-back{
        top: 60%;
      }
    }
  }

  .box-item {
    //スライド時に使用
    &.slide1{
      // background-image: url('/inc/image/top/mainimg_01.jpg');
//      display: none;
      @media screen and (max-width: 768px) {
        background-image: url('/inc/image/top/mainimg_01.jpg');
        background-size: cover;
      }
    }
    &.slide2{
//      display: none;
      @media screen and (max-width: 768px){
        display: block;
        background-image: url('/inc/image/top/mainimg_02.jpg');
        background-size: cover;
      }
    }
    &.slide3{
//      display: none;
      @media screen and (max-width: 768px){
        display: block;
        background-image: url('/inc/image/top/mainimg_03.jpg');
        background-size: cover;
      }
    }
    &.slide4{
//      display: none;
      @media screen and (max-width: 768px){
        display: block;
        background-image: url('/inc/image/top/mainimg_04.jpg');
        background-size: cover;
      }
    }
    &.slide5{
//      display: none;
      @media screen and (max-width: 768px){
        display: block;
        background-image: url('/inc/image/top/mainimg_05.jpg');
        background-size: cover;
      }
    }
    position: relative;
    color: #fff;
    background-attachment: fixed;
    background-size: cover;
    background-position: center top;
    min-height: 550px;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);

    @media screen and (max-width: 768px) {
      background-size: auto;
      background-attachment:inherit;
      background-position: center center;
      min-height: 300px;
    }

    .image {
      width: 100%;
      img {
        width: 100%;
      }
    }

    .box-text-main {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      z-index: 1;
      text-align: center;

      @media screen and (max-width: 768px) {
        //top: 70px;
      }

      .ttl-main {
          font-weight: 200;
//          font-size: 70px;
          font-size: 7.7rem;
          line-height: 1.1;
          font-family: 'Noto Serif Japanese';

        @media screen and (max-width: 768px) {
          padding: 0 10px;
//          font-size: 30px;
          font-size: 7.7rem;
        }
        @media screen and (max-width: 600px){
          font-size: 3.3rem;
        }
      }
      .ttl-sub {
        font-weight: 200;
        font-size: 18px;
        font-size: 1.8rem;
        font-family: 'Noto Serif Japanese';

        @media screen and (max-width: 768px) {
          padding: 0 45px;
//          font-size: 12px;
          font-size: 1.8rem;
        }
        @media screen and (max-width: 600px){
          font-size: 1.2rem;
        }
      }
    }

  }

  &:hover {
    .slick-prev, .slick-next {
      display: block !important;
    }
  }

  .slick-prev, .slick-next {
    overflow: hidden;
    position: absolute;
    z-index: 1001; //for IE9
    top: 50%;
    width: 30px;
    height: 58px;
    text-indent: -999px;
    transform: translateY(-50%);
    outline: none;

    @media screen and (min-width: 769px) {
      display: none !important;

      &:hover {
        opacity: 0.7;
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
      width: 15px;
      height: 29px;
    }
  }
  .slick-prev {
    left: 15px;
    background: url(/inc/image/top/btn_slide_prev.png) no-repeat;

    @media screen and (max-width: 768px) {
      left: 6px;
      background-size: 15px 29px;
    }
  }
  .slick-next {
    right: 15px;
    background: url(/inc/image/top/btn_slide_next.png) no-repeat;

    @media screen and (max-width: 768px) {
      right: 6px;
      background-size: 15px 29px;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 15px;
    z-index: 1001; //for IE9
    width: 100%;
    padding: 0 15px;
    text-align: right;
    font-size: 0;

    @media screen and (max-width: 768px) {
      padding: 0;
      bottom: 60px;
      text-align: center;
    }

    li {
      display: inline-block;
      padding: 0 3px;

      button {
        overflow: hidden;
        width: 8px;
        height: 8px;
        border-radius: 6px;
        background: #666;
        text-indent: -999px;
        outline: none;
        &:hover {
          background: #cccc00;
        }
      }
      &.slick-active button {
        background: #f2f2f278;
      }
    }
  }
}

/* sec-top-01
---------------------------------------------------------- */
.sec-top-01 {
  padding-bottom: 25px;
}

.sec-top-02, .sec-top-03, .sec-top-04, .sec-top-05 {
  position: relative;
  padding: 30px 0;

  @media screen and (max-width: 768px) {
    padding-top: 25px;
  }
}

.sec-top-03 {
  padding-bottom: 50px;
  background: #f7f7f7;
}
.sec-top-04 {
  .ttl-cmn-03 {
    margin-bottom: 30px;
  }
}
.sec-top-05 {
  div.container {
    text-align: center;
    margin: 0 auto 2% auto;
  }
}

.sec-top-disaster {
  div.container {
    text-align: center;
    margin: 0 auto 2% auto;
    p {
      border: 2px solid lightgray;
      border-radius: 5px;
      padding: 2% 10%;
      display: inline-block;
      br.hide_sp {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

/* mod-top-tab-01
---------------------------------------------------------- */
.mod-top-tab-01 {
  position: relative;
  z-index: 2;
  width: 830px;
  margin: -80px auto 30px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: -50px auto 15px;
  }

  .list-button {
    margin-left: -10px;

    @include clearfix();

    @media screen and (max-width: 768px) {
      margin-left: -4px;
    }

    li {
      float: left;
      position: relative;
      width: 25%;
      padding-left: 10px;

      @media screen and (max-width: 768px) {
        padding-left: 4px;
      }

      &.is-active:after {
        position: absolute;
        bottom: -31px;
        left: 50%;
        width: 20px;
        height: 16px;
        margin-left: -10px;
        background: url(/inc/image/top/ico_arrow_border.png) no-repeat;
        content: "";

        @media screen and (max-width: 768px) {
          bottom: -16px;
          width: 12px;
          height: 11px;
          background: url(/inc/image/top/ico_arrow_border_sp.png) no-repeat;
          background-size: 12px 11px;
        }
      }
    }
  }

  .btn-tab {
    overflow: hidden;
    position: relative;
    display: block;
    height: 145px;
    padding-top: 24px;
    border-radius: 5px;
    background :url(/inc/image/top/bg_top_tab.png) center center repeat-x;
    text-align: center;
    box-shadow: 0 0 4px rgba(0,0,0,.5);
    transition: all $headerFixedAnimationSpeed ;

    @media screen and (max-width: 768px) {
      height: 73px;
      padding-top: 9px;
      box-shadow: 0 0 8px rgba(0,0,0,.45);
      background-size: auto 73px;
    }

    .title {
      padding-top: 70px;
      color: #fff;
      font-size: 30px;
      font-size: 3rem;
      line-height: .8;
      background-size: 50px 50px;
      background-position: top center;
      background-repeat: no-repeat;
      transition: color $headerFixedAnimationSpeed;

      @media screen and (max-width: 768px) {
        height: 73px;
        padding-top: 38px;
        font-size: 15px;
        font-size: 1.5rem;
        background-size: 30px 30px;
      }

      span {
        font-size: 23px;
        font-size: 2.3rem;

        @media screen and (max-width: 768px) {
          font-size: 12px;
          font-size: 1.2rem;
        }
      }

      &.icon-01 {
        background-image: url(/inc/image/top/ico_tab_01_no.png);
      }
      &.icon-02 {
        background-image: url(/inc/image/top/ico_tab_02_no.png);
      }
      &.icon-03 {
        background-image: url(/inc/image/top/ico_tab_03_no.png);
      }
      &.icon-04 {
        background-image: url(/inc/image/top/ico_tab_04_no.png);
      }
    }
    // /.title

    &.external{ //外部リンクタブ
      &:after{
        position: absolute;
        top: 15px;
        right: 7px;
        width: 16px;
        height: 14px;
        margin-top: -7px;
        background: url(/inc/image/top/ico_blank_02.png) no-repeat;
        background-size: 16px 14px;
        content: "";
      }
    }

    &:hover{
      box-shadow: $hoverAnimationShadow;
      transform: scale($imgAnimationAfterScale);
    }

    &:hover, &.is-active {
      .title {
        color: #fcff04;
      }
      .icon-01 {
        background-image: url(/inc/image/top/ico_tab_01_on.png);
      }
      .icon-02 {
        background-image: url(/inc/image/top/ico_tab_02_on.png);
      }
      .icon-03 {
        background-image: url(/inc/image/top/ico_tab_03_on.png);
      }
      .icon-04 {
        background-image: url(/inc/image/top/ico_tab_04_on.png);
      }
    }

    &.is-active {
      &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 5px;
        background-color: #fcff04;
        content: "";
      }
    }
  }
}

/* box-top-news
---------------------------------------------------------- */
.box-top-news {
  position: relative;
  // padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }

  .btn-news-top {
    position: absolute;
    white-space: nowrap;
    top: 5px;
    right: 0;
    width: 85px;
    background-color: #FFF;
    font-size: 14px;
    font-size: 1.4rem;
    span {
      margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
      //display: none;
      position: relative;
      width: 100%;
      margin-top: 20px;
    }
  }

  .box-news-highlight {
    margin-bottom: 20px;
  }
}

/* box-tab-content
---------------------------------------------------------- */
.box-tab-content {
  position: relative;
  padding: 45px 0 30px;
  border-top: 1px solid #c0bebe;

  @media screen and (max-width: 768px) {
    padding: 20px 0 15px;
  }

  &:before, &:after {
    position: absolute;
    top: -1px;
    left: 0;
    width: 8px;
    height: 20px;
    background: url(/inc/image/top/bg_line_corner_01.png) no-repeat;
    content: "";

    @media screen and (max-width: 768px) {
      width: 4px;
      height: 10px;
      background-size: 4px 10px !important;
    }
  }
  &:after {
    left: auto;
    right: 0;
    background: url(/inc/image/top/bg_line_corner_02.png) no-repeat;
  }

  .box-item{
    .card-cmn-01{
      margin-bottom: 10px;
    }
  }
}

/* list-top-news-01
---------------------------------------------------------- */
.list-top-news-01 {
  li {
    border-bottom: 1px solid #e0e0e0;
    &:last-child {
      border-bottom: none;
    }
    @media screen and (max-width: 768px) {
      margin: 0 -10px;
      padding: 0 10px;
    }
  }

  .box-news-item {
    display: table;
    width: 100%;
    padding: 8px 10px;
    transition: background-color $headerFixedAnimationSpeed;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 0;
    }

    @media screen and (min-width: 769px) {
      &:hover {
        background: #f0f0f0;
      }
    }

    .box-tag {
      display: table-cell;
      width: 112px;
      vertical-align: top;

      @media screen and (max-width: 768px) {
        display: block;
        width: 50%;
        order: 1;
      }
    }
    .txt-date {
      display: table-cell;
      width: 125px;
      vertical-align: top;

      @media screen and (max-width: 768px) {
        display: block;
        width: 50%;
        padding-top: 3px;
        text-align: right;
        order: 2;
      }
    }
    .txt-info {
      display: table-cell;
      padding: 0 15px;
      vertical-align: top;
      font-weight: 500;

      @media screen and (max-width: 768px) {
        display: block;
        width: 100%;
        padding: 8px 0 0;
        order: 3;
      }
    }
  }
}

// 一時的なニュース表示
.temporary-news {
  margin: 40px 0 20px;
  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }
  .ttl-cmn-03 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
  .wrap {
    padding: 10px 0;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #fff;
    li {
      margin: 0 5px;
      border-bottom: 1px solid #e0e0e0 !important;
    }
    .color-co-event {
      background-color: #c91818;
    }
  }
}

/* list-top-news-02
---------------------------------------------------------- */
.list-top-news-02 {
  li {
    border-bottom: 1px solid #e0e0e0;
  }

  .item-news {
    padding: 8px 10px;

    @media screen and (max-width: 768px) {
      padding: 8px 5px 6px;
    }

    .box-head {
      width: 100%;
      margin-bottom: 4px;

      @media screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
      }

      .txt-date {
        display: inline-block;
        line-height: 1.2;

        @media screen and (max-width: 768px) {
          order: 2;
          display: block;
          padding-top: 5px;
        }
      }
      .box-tag {
        display: inline-block;
        padding-left: 10px;

        @media screen and (max-width: 768px) {
          order: 1;
          display: block;
          padding-left: 0;
        }
      }
    }

    @media screen and (min-width: 769px) {
      &:hover {
        .txt-info {
          color: $colorBlue01;
          text-decoration: underline;
        }
      }
    }
  }
}

/* list-top-recruit-link
---------------------------------------------------------- */
.list-top-recruit-link {
  li {
    overflow: hidden;
    border: 1px solid #e0e0e0;
    border-bottom: none;

    &:first-child {
      border-bottom: none;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-child {
      border-bottom: 1px solid #e0e0e0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    a {
      position: relative;
      display: block;
      width: 100%;
      border: none;
      border-radius: 0;
      padding: 10px 30px 10px 15px;
      color: #000;
      text-align: left;
      font-weight: 500;

      &:after {
        position: absolute;
        top: 50%;
        right: 10px;
        width: 16px;
        height: 14px;
        margin-top: -7px;
        background: url(/inc/image/top/ico_blank_01.png) 0 0 no-repeat;
        background-size: 16px 14px;
        content: "";
      }

      @media screen and (min-width: 769px) {
        &:hover:after {
          background: url(/inc/image/top/ico_blank_02.png) 0 0 no-repeat;
          background-size: 16px 14px;
        }
      }
    }
  }
}


/* list-top-banner
---------------------------------------------------------- */
.list-top-banner {
  overflow: hidden;
  margin: -10px -5px 0;
  text-align: center;
  @media screen and (max-width: 768px) {
    margin: -20px 0 0 0;
  }
  li {
    width: 49%;
    margin-top: 20px;
    padding: 5px;
    display: inline-block;
    @media screen and (max-width: 768px) {
      float: none;
      width: 100%;
      padding-left: 0;
    }
    @media screen and (min-width: 769px) {
      &:hover {
        .box-image img {
          transform: scale($imgAnimationAfterScale);
        }
      }
    }
  }
  .box-image {
    overflow: hidden;
    border-radius: 5px;
    img {
      display: block;
      width: 100%;
      transition: transform $headerFixedAnimationSpeed ease-in-out;
    }
  }
  .text {
    margin-top: 16px;
    font-size: 16px;
    font-size: 1.6rem;
    text-align: center;
    @media screen and (max-width: 768px) {
      margin-top: 8px;
      font-size: 14px;
      font-size: 1.4rem;
    }
  }
  .ico-blank:after {
    vertical-align: text-top;
}
}


/* brand_banner
---------------------------------------------------------- */
.brand_banner {
  overflow: hidden;
  margin: -10px -5px 0;
  text-align: start;
  @media screen and (max-width: 768px) {
    text-align: center;
    max-width: 330px;
    margin: 0 auto;
  }
  li {
    width: 33%;
    margin-top: 20px;
    padding: 5px;
    display: inline-block;
    @media screen and (max-width: 768px) {
      float: none;
      width: 100%;
      padding-left: 0;
      margin-top: 10px;
    }
    @media screen and (min-width: 769px) {
      &:hover {
        .box-image img {
          transform: scale($imgAnimationAfterScale);
        }
      }
    }
  }
  .box-image {
    overflow: hidden;
    border-radius: 5px;
    img {
      display: block;
      width: 100%;
      transition: transform $headerFixedAnimationSpeed ease-in-out;
    }
  }
  .text {
    margin-top: 16px;
    font-size: 16px;
    font-size: 1.6rem;
    text-align: center;
    @media screen and (max-width: 768px) {
      margin-top: 8px;
    }
  }
  .ico-blank:after {
    vertical-align: text-top;
  }
}
/* box-top-article-01
---------------------------------------------------------- */
.box-top-article-01 {
  overflow: hidden;

  .box-side-01 {
    float: right;
    width: 400px;
    padding-left: 20px;

    @media screen and (max-width: 768px) {
      float: none;
      width: auto;
      margin-bottom: 22px;
      padding-left: 0;
    }
  }
  .box-side-02 {
    float: left;
    width: 600px;

    @media screen and (max-width: 768px) {
      float: none;
      width: auto;

    }
  }

  .title-main {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    font-size: 1.6rem;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      font-size: 1.2rem;
    }
  }
}

/* box-top-shop-01
---------------------------------------------------------- */
.box-top-shop-01 {
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
}

/* box-top-slider-01
---------------------------------------------------------- */
.box-top-slider-01 {
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }

  .item-slider {
    a {
      outline: none;

      @media screen and (min-width: 769px) {
        & img {
          transition: opacity $headerFixedAnimationSpeed ease;
        }
        &:hover img {
          opacity: 0.7;
        }
      }
    }
    img {
      width: 100%;
    }
  }

  .slick-dots {
    text-align: center;
    padding-top: 9px;
    font-size: 0;

    @media screen and (max-width: 768px) {
      padding-top: 5px;
    }

    li {
      display: inline-block;
      padding: 0 3px;

      button {
        overflow: hidden;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #d2d2d2;
        text-indent: -999px;

        @media screen and (max-width: 768px) {
          width: 6px;
          height: 6px;
        }
      }

      &.slick-active button {
        background-color: $colorBlue01;
      }
    }
  }
}

/* box-top-btnlink-01
---------------------------------------------------------- */
.box-top-btnlink-01 {
  .list-link {
    li {
      margin-bottom: 10px;

      @media screen and (max-width: 768px) {
        margin-bottom: 5px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .box-link-01 {
    overflow: hidden;
    position: relative;
    display: table;
    width: 100%;
    height: 60px;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    transition: border-color $hoverAnimationSpeed,box-shadow $hoverAnimationSpeed;

    &:hover{
      // background-color: #fafafa;
      box-shadow: $hoverAnimationShadow;
      // border: 1px solid #003399;
      .title{
        color: #003399;
      }
    }
    @media screen and (max-width: 768px) {
      height: 40px;
      margin-bottom: 5px;
    }

    &.ico-blank:after {
      position: absolute;
      top: 50%;
      right: 7px;
      width: 16px;
      height: 14px;
      margin-top: -7px;
      background: url(/inc/image/top/ico_blank_01.png) no-repeat;
      background-size: 16px 14px;
      content: "";
    }

    .icon {
      display: table-cell;
      width: 54px;
      border-right: 1px solid #cdcdcd;
      transition: background-color $headerFixedAnimationSpeed;

      @media screen and (max-width: 768px) {
        width: 34px;
      }

      &.ico-01 {
        background: url(/inc/image/top/ico_top_link_01_no.png) center center no-repeat;

        @media screen and (max-width: 768px) {
          background: url(/inc/image/top/ico_top_link_01_sp.png) center center no-repeat;
          background-size: 19px 20px;
        }
      }
      &.ico-02 {
        background: url(/inc/image/top/ico_top_link_02_no.png) center center no-repeat;

        @media screen and (max-width: 768px) {
          background: url(/inc/image/top/ico_top_link_02_sp.png) center center no-repeat;
          background-size: 19px 20px;
        }
      }
      &.ico-03 {
        background: url(/inc/image/top/ico_top_link_03_no.png) center center no-repeat;
        background-size: 23px;

        @media screen and (max-width: 768px) {
          background: url(/inc/image/top/ico_top_link_03_sp.png) center center no-repeat;
          background-size: 19px 20px;
        }
      }
      &.ico-04 {
        background: url(/inc/image/top/ico_top_link_04_no.png) center center no-repeat;
        background-size: 14px 26px;
      }
      &.ico-05 {
        background: url(/inc/image/top/ico_top_link_05_no.png) center center no-repeat;
        background-size: 22px 22px;
      }
      &.ico-06 {
        background: url(/inc/image/top/ico_top_link_06_no.png) center center no-repeat;
        background-size: 26px 21px;
      }
      &.ico-07 {
        background: url(/inc/image/top/ico_top_link_07_no.png) center center no-repeat;
        //background-size: 26px 21px;
      }
      &.ico-08 {
        background: url(/inc/image/top/ico_top_link_08_no.png) center center no-repeat;
        background-size: 25px 25px;
      }
      &.ico-09 {
        background: url(/inc/image/top/ico_top_link_09_no.png) center center no-repeat;
        background-size: 23px 23px;

        @media screen and (max-width: 768px) {
          background: url(/inc/image/top/ico_top_link_09_sp.png) center center no-repeat;
          background-size: 20px 20px;
        }
      }
      &.ico-10 {
        background: url(/inc/image/top/ico_top_link_10_no.png) center center no-repeat;
        background-size: 25px;

        @media screen and (max-width: 768px) {
          background: url(/inc/image/top/ico_top_link_10_sp.png) center center no-repeat;
          background-size: 20px;
        }
      }
      &.ico-11 {
        background: url(/inc/image/top/ico_top_link_11_no.png) center center no-repeat;
        background-size: 26px;

        @media screen and (max-width: 768px) {
          background: url(/inc/image/top/ico_top_link_11_sp.png) center center no-repeat;
          background-size: 22px;
        }
      }
		 &.ico-12 {
        background: url(/inc/image/top/ico_top_link_12_no.png) center center no-repeat;
        background-size: 30px 30px;
      }
      &.ico-13 {
        background: url(/inc/image/top/ico_top_link_13_no.png) center center no-repeat;
      }
      &.ico-14 {
        background: url(/inc/image/top/ico_top_link_14_no.png) center center no-repeat;
      }
    }

    @media screen and (min-width: 769px) {
      &:hover .icon {
        background-color: $colorBlue01 !important;

        &.ico-01 {
          background: url(/inc/image/top/ico_top_link_01_on.png) center center no-repeat;
        }
        &.ico-02 {
          background: url(/inc/image/top/ico_top_link_02_on.png) center center no-repeat;
        }
        &.ico-03 {
          background: url(/inc/image/top/ico_top_link_03_on.png) center center no-repeat;
        }
        &.ico-04 {
          background: url(/inc/image/top/ico_top_link_04_on.png) center center no-repeat;
          background-size: 14px 26px;
        }
        &.ico-05 {
          background: url(/inc/image/top/ico_top_link_05_on.png) center center no-repeat;
          background-size: 22px 22px;
        }
        &.ico-06 {
          background: url(/inc/image/top/ico_top_link_06_on.png) center center no-repeat;
          background-size: 26px 21px;
        }
        &.ico-07 {
          background: url(/inc/image/top/ico_top_link_07_on.png) center center no-repeat;
          //background-size: 26px 21px;
        }
        &.ico-08 {
          background: url(/inc/image/top/ico_top_link_08_on.png) center center no-repeat;
          background-size: 25px 25px;
        }
        &.ico-09 {
          background: url(/inc/image/top/ico_top_link_09_on.png) center center no-repeat;
          background-size: 23px 23px;
        }
        &.ico-10 {
          background: url(/inc/image/top/ico_top_link_10_on.png) center center no-repeat;
          background-size: 25px;
        }
        &.ico-11 {
          background: url(/inc/image/top/ico_top_link_11_on.png) center center no-repeat;
          background-size: 26px;
        }
        &.ico-12 {
          background: url(/inc/image/top/ico_top_link_12_on.png) center center no-repeat;
          background-size: 30px 30px;
        }
        &.ico-13 {
          background: url(/inc/image/top/ico_top_link_13_on.png) center center no-repeat;
        }
        &.ico-14 {
          background: url(/inc/image/top/ico_top_link_14_on.png) center center no-repeat;
        }
      }
    }

    .info {
      display: table-cell;
      padding: 5px 30px 5px 14px;
      line-height: 1.4;
      vertical-align: middle;

      @media screen and (max-width: 768px) {
        padding: 5px 25px 5px 9px;
      }

      .title {
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;

        @media screen and (max-width: 768px) {
          font-size: 15px;
          font-size: 1.5rem;
        }
      }
      .text {
        color: #393939;
        font-size: 11px;
        font-size: 1.1rem;
        opacity: 0.7;

        @media screen and (max-width: 768px) {
          font-size: 9px;
          font-size: 0.9rem;
        }
      }
    }
  }

  .box-link-02 {
    overflow: hidden;
    position: relative;
    display: table;
    width: 100%;
    height: 60px;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    transition: box-shadow $hoverAnimationSpeed;
    &:hover{
      box-shadow: $hoverAnimationShadow;
    }

    @media screen and (max-width: 768px) {
      height: 40px;
      margin-bottom: 5px;
    }

    &.ico-blank:after {
      position: absolute;
      top: 50%;
      right: 7px;
      width: 16px;
      height: 14px;
      margin-top: -7px;
      background: url(/inc/image/top/ico_blank_03.png) no-repeat;
      background-size: 16px 14px;
      content: "";
    }

    .icon {
      overflow: hidden;
      display: table-cell;
      width: 66px;

      @media screen and (max-width: 768px) {
        width: 33px;
      }

      &.ico-01 {
        background: url(/inc/image/top/img_top_button_01.jpg) center center no-repeat;
        background-size: cover;
      }
      &.ico-02 {
        background: url(/inc/image/top/img_top_button_02.jpg) center center no-repeat;
        background-size: cover;
      }
      &.ico-03 {
        background: url(/inc/image/top/img_top_button_03.jpg) center center no-repeat;
        background-size: cover;
      }
    }

    .info {
      display: table-cell;
      padding: 5px 13px;
      text-align: left;
      vertical-align: middle;
    }
  }
}

/* btn-top-article-01
---------------------------------------------------------- */
.btn-top-article-01 {
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    margin-top: 5px;
  }
}

/* box-top-service-01
---------------------------------------------------------- */
.box-top-service-01 {
  .box-service-search {
    display: table;
    width: 99.999%;
    padding: 12px;
    border: 1px solid #cdcdcd;
    background-color: #f1f1f1;

    @media screen and (max-width: 768px) {
      display: block;
      width: 100%;
    }

    .box-title {
      display: table-cell;
      width: 130px;
      padding-left: 5px;
      vertical-align: middle;
      text-align: left;

      @media screen and (max-width: 768px) {
        display: block;
        width: auto;
        padding-left: 0;
      }

      .ico-search {
        display: inline-block;
        padding-left: 25px;
        color: $colorBlue01;
        background: url(/inc/image/top/ico_top_search.png) left center no-repeat;
        background-size: 19px 19px;
        font-weight: 700;
        font-size: 16px;
        font-size: 1.6rem;

        @media screen and (max-width: 768px) {
          display: block;
          width: auto;
          margin-bottom: 5px;
          padding-left: 16px;
          font-size: 14px;
          font-size: 1.4rem;
          background-size: 13px 13px;
        }
      }
    }
    .box-search {
      overflow: hidden;
      display: table-cell;
      padding: 4px;
      background-color: #fff;
      border: 1px solid #bfbfbf;
      border-radius: 3px;
      transition: all $hoverAnimationSpeed;

      @media screen and (max-width: 768px) {
        display: flex;
        padding: 2px;
      }

      &:active,
      &:hover{
        border-color: $inputActiveColor;
        box-shadow: $hoverAnimationShadow;
      }

      form{
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .input-search {
        float: left;
        padding: 4px 8px;
        width: 355px;
        background: none;
        border: none;
        font-size: 15px;
        font-size: 1.5rem;

        @media screen and (max-width: 768px) {
          float: none;
          flex: 1;
          width: calc(100% - 63px);
          padding: 7px 4px;
          font-size: 13px;
          font-size: 1.3rem;
        }

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #878787;
          opacity: 1;
        }

        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #878787;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #878787;
          opacity: 1;
        }

        &:-moz-placeholder {
          /* Firefox 18- */
          color: #878787;
          opacity: 1;
        }
        &.placeholdersjs {
          /* Firefox 18- */
          color: #878787;
          opacity: 1;
        }
      }
      .btn-search {
        float: right;
        width: 76px;
        height: 33px;
        background-color: $colorBlue01;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        font-size: 1.5rem;
        @include hoverOpacity();

        @media screen and (max-width: 768px) {
          float: none;
          flex: 0 0 60px;
          width: 60px;
          font-size: 11px;
          font-size: 1.1rem;
        }
      }
    }

  }
}

/* list-top-service-01
---------------------------------------------------------- */
.list-top-service-01 {
  // overflow: hidden;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #cdcdcd;
  }

  li {
    float: left;
    width: 33.333%;
    border-right: 1px solid #cdcdcd;
    border-bottom: 1px solid #cdcdcd;

    @media screen and (min-width: 769px) {
      &:nth-child(3n+1) {
        clear: left;
        border-left: 1px solid #cdcdcd;
      }
    }

    @media screen and (max-width: 768px) {
      float: none;
      width: 50%;
    }
  }
}

/* box-top-service-text
---------------------------------------------------------- */
.box-top-service-text {
  margin-top: 22px;

  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }

  .list-service {
    overflow: hidden;
    margin-top: -2px;
    padding-left: 15px;

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }

    li {
      float: left;
      position: relative;
      width: 185px;
      margin-top: 2px;
      padding: 0 15px 0 14px;
      font-size: 14px;
      font-size: 1.4rem;

      @media screen and (max-width: 768px) {
        width: 50%;
        padding: 0 10px 0 12px;
        font-size: 12px;
        font-size: 1.2rem;
      }

      @media screen and (min-width: 769px) {
        &:nth-child(3n+1) {
          width: 170px;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "・";
      }
    }
  }
}

/* box-recruit-visual
---------------------------------------------------------- */
.box-recruit-visual {
  position: relative;
  margin-bottom: 25px;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }

  .image img {
    width: 100%;
  }

  .box-button {
    position: absolute;
    left: 22px;
    bottom: 38px;

    @media screen and (max-width: 768px) {
      left: 10px;
      right: 10px;
      bottom: 10px;
    }
  }

  .btn-link {
    display: block;
    width: 280px;
    padding: 6px 30px;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 0;
    font-size: 14px;
    font-size: 1.4rem;
    font-weight: 500;

    @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 12px;
      font-size: 1.2rem;
    }

    &:before {
      background-color: #fff;
    }
    &:after {
      right: 6px;
      width: 16px;
      height: 14px;
      margin-top: -8px;
      background: url(/inc/image/top/ico_blank_02.png) no-repeat;
      background-size: 16px 14px;
    }

    @media screen and (min-width: 769px) {
      &:hover {
        color: #393939;

        &:after {
          background: url(/inc/image/top/ico_blank_01.png) no-repeat;
          background-size: 16px 14px;
        }
      }
    }
  }
}

/* box-recruit-article-01
---------------------------------------------------------- */
.box-recruit-article-01 {
  overflow: hidden;

  .box-subside-01 {
    float: left;
    width: 290px;

    @media screen and (max-width: 768px) {
      float: none;
      width: auto;
      margin-bottom: 15px;
    }
  }
  .box-subside-02 {
    float: left;
    width: 310px;
    padding-left: 18px;

    @media screen and (max-width: 768px) {
      float: none;
      width: auto;
      padding-left: 0;
    }
  }
}

/* box-mynavi-link
---------------------------------------------------------- */
.box-mynavi-link {
  overflow: hidden;
  position: relative;
  margin-bottom: 12px;
  border: 1px solid #bfbfbf;

  a {
    img {
      width: 100%;
      transition: transform $headerFixedAnimationSpeed ease-in-out;
    }
    &:hover img {
      transform: scale($imgAnimationAfterScale);
    }
  }
}

/* card-top-service-01
---------------------------------------------------------- */
.card-top-service-01, .card-top-service-02 {
  position: relative;
  transition: box-shadow $hoverAnimationSpeed;

  &:hover{
    position: relative;
    box-shadow: $hoverAnimationShadow;
    z-index: 100
  }

  .image {
    overflow: hidden;
    width: 100%;
    height: 175px;

    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 768px) {
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .title {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    font-size: 16px;
    font-size: 1.6rem;

    @media screen and (max-width: 768px) {
      top: 5px;
      left: 6px;
      right: 6px;
      font-size: 14px;
      font-size: 1.4rem;
    }
  }
}

/* card-top-service-02
---------------------------------------------------------- */
.card-top-service-02 {
  .image {
    height: 185px;

    @media screen and (max-width: 768px) {
      height: auto;
    }
  }
}


/*穀物乾燥調製機械・所要動力（電力）一覧*/
.box-top-btnlink-left {
	width: 50%;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
 /* margin: 5em auto;*/
	.box-link-01 {
		background-color: #FFF699;
    text-align: center;
		overflow: hidden;
    position: relative;
    display: table;
    width: 100%;
    height: 60px;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    -webkit-transition: border-color 0.25s, -webkit-box-shadow 0.25s;
    transition: border-color 0.25s, -webkit-box-shadow 0.25s;
    -o-transition: border-color 0.25s, box-shadow 0.25s;
    transition: border-color 0.25s, box-shadow 0.25s;
    transition: border-color 0.25s, box-shadow 0.25s, -webkit-box-shadow 0.25s;
    &:hover{
      box-shadow: $hoverAnimationShadow;
      .title{
        color: #003399;
      }
    }
		.icon {
			display: table-cell;
			width: 54px;
			border-right: 1px solid #cdcdcd;
			-webkit-transition: background-color 0.2s;
			-o-transition: background-color 0.2s;
			transition: background-color 0.2s;
		}
		.icon.ico-04 {
			background: url(/inc/image/top/ico_top_link_04_no.png) center center no-repeat;
    	background-size: 14px 26px;
		}
		.info {
			display: table-cell;
			padding: 5px 30px 5px 14px;
			line-height: 1.4;
			vertical-align: middle;
    }
    .title {
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: 500;

      @media screen and (max-width: 768px) {
        font-size: 15px;
        font-size: 1.5rem;
      }
    }
    @media screen and (min-width: 769px) {
      &:hover .icon {
        background-color: $colorBlue01 !important;
        &.ico-04 {
          background: url(/inc/image/top/ico_top_link_04_on.png) center center no-repeat;
          background-size: 14px 26px;
        }
      }
    }
	}
}
/* /穀物乾燥調製機械・所要動力（電力）一覧 */




/*穀物乾燥調製機械　取扱説明書*/
.box-top-btnlink-right {
	width: 50%;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
 /* margin: 5em auto;*/
	.box-link-01 {
		background-color: #ffdc99;
    text-align: center;
		overflow: hidden;
    position: relative;
    display: table;
    width: 100%;
    height: 60px;
    border: 1px solid #cdcdcd;
    border-radius: 4px;
    -webkit-transition: border-color 0.25s, -webkit-box-shadow 0.25s;
    transition: border-color 0.25s, -webkit-box-shadow 0.25s;
    -o-transition: border-color 0.25s, box-shadow 0.25s;
    transition: border-color 0.25s, box-shadow 0.25s;
    transition: border-color 0.25s, box-shadow 0.25s, -webkit-box-shadow 0.25s;
    &:hover{
      box-shadow: $hoverAnimationShadow;
      .title{
        color: #003399;
      }
    }
		.icon {
			display: table-cell;
			width: 54px;
			border-right: 1px solid #cdcdcd;
			-webkit-transition: background-color 0.2s;
			-o-transition: background-color 0.2s;
			transition: background-color 0.2s;
		}
		.icon.ico-12 {
			background: url(/inc/image/top/ico_top_link_12_no.png) center center no-repeat;
    	background-size: 30px 30px;
		}
		.info {
			display: table-cell;
			padding: 5px 30px 5px 14px;
			line-height: 1.4;
			vertical-align: middle;
    }
    .title {
      font-size: 16px;
      font-size: 1.6rem;
      font-weight: 500;

      @media screen and (max-width: 768px) {
        font-size: 15px;
        font-size: 1.5rem;
      }
    }
    @media screen and (min-width: 769px) {
      &:hover .icon {
        background-color: $colorBlue01 !important;
        &.ico-12 {
          background: url(/inc/image/top/ico_top_link_12_on.png) center center no-repeat;
          background-size: 30px 30px;
        }
      }
    }
	}
}
/* /穀物乾燥調製機械　取扱説明書 */

.bnr_box {
	max-width: 1000px;
	display: flex;
	justify-content: center;
	margin: 0 auto;
	margin-top: 30px;
	margin-bottom: 30px;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		padding-left: 10px;
		padding-right: 10px;
	}

}
.info-2 {
	 margin-right: 10px !important;
	@media screen and (max-width: 768px) {
		margin-right: 0px !important;
		margin-bottom: 10px;
	}
}
.info-3 {
	 margin-left: 10px !important;
	@media screen and (max-width: 768px) {
		margin-left: 0px !important;
	}
}

/* ==========================================================
 * Variables
 *==========================================================*/

$baseFontColor: #444;

$colorWhite: #ffffff;
$colorBlue01: #003399;
$colorYellow01: #fff000;

$AnchorHoverTextColor: darken($colorBlue01,6);

$headerHeight: 80px;
$headerFixedHeight: 55px;
$headerFixedAnimationSpeed: .2s;
$headerLineHeight: 6px;

$hoverAnimationSpeed: .25s;
$hoverAnimationShadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
$imgAnimationAfterScale: 1.04;

$inputActiveColor: #f8d400;

$defaultFontFamily: 'Noto Sans JP', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

/* ==========================================================
 * Mixin
 *==========================================================*/
@mixin hoverOpacity {
  -webkit-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &:hover {
    opacity: 0.7;
    @media screen and (max-width: 768px) {
      opacity: 1;
    }
  }
}

@mixin hoverBrightness {
  &:hover {
    filter: brightness(120%);
			transition:filter .3s;
  }
}

@mixin isAnimate {
  -webkit-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin font-yukyo {
  font-family: 'YuKyo-Medium','游教科書体 M', "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "游明朝体", "Yu Mincho", YuMincho, "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@mixin clearFix {
  &:before,
  &:after{
    content: " ";
    display: table;
  }

  &:after{
    display: block;
    clear: both;
    height: 1px;
    margin-top: -1px;
    visibility: hidden;
  }
  &{
    *zoom: 1;
  }
}

@mixin news_title {
  margin-top: 0.5em;
  padding: 0;
  color: #888888;
  font-size: 150%;
  font-weight: bold;
  line-height: 120%;
  text-align: center;
}

@mixin news_subtitle {
  margin: 1em 0 2em;
  padding: 0;
  color: #000000;
  font-size: 120%;
  font-weight: normal;
  line-height: 120%;
  text-align: center;
}
